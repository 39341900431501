import React, {FC, useState, useLayoutEffect, ReactNode, memo, useCallback, useEffect} from 'react';
import {Input} from '../form';
import {arrowDownIcon} from '../../images/svg-icons';
import useClickOutside from '../../hooks/useClickOutside';
import './styles.scss';

interface ISelect {
  value?: any;
  options?: any;
  onChange?: any;
  children?: ReactNode;
  disabled?: boolean;
  manySelect?: boolean;
  className?: string;
  placeholder?: string | ReactNode;
  bottom?: any;
  icon?: ReactNode;
  id?: string;
  showLeft?: boolean;
  showRight?: boolean;
  showBottom?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  width?: number;
  noTextTransform?: boolean;
  fullwidth?: any;
  label?: string;
  danger?: boolean;
  w100?: boolean;
  textDefault?: boolean;
  textUppercase?: boolean;
  searchable?: boolean;
}

const Select: FC<ISelect> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  placeholder,
  bottom,
  icon,
  showLeft,
  showRight,
  showBottom,
  fullWidth,
  noBorder,
  width,
  label,
  danger,
  w100,
  textDefault,
  textUppercase,
  searchable,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside(false, val => {
    setIsShow(val);
  });
  const [isShow, setIsShow] = useState(false);
  const [docHeigth, setDocHeight] = useState<number>(0);
  const [elOffsetHeigth, setElOffsetHeigth] = useState<number>(0);
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [newOptions, setNewOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useLayoutEffect(() => {
    setDocHeight(document.documentElement.clientHeight);
  }, []);

  useLayoutEffect(() => {
    if (selectRef && selectRef.current) {
      const dimensions = selectRef.current.getBoundingClientRect();
      setElOffsetHeigth(dimensions.y);
    }
  }, [selectRef]);

  const res = docHeigth - elOffsetHeigth;

  const getClassNamesByHeight = useCallback(() => {
    return res < 330 && res > 150 ? 'short-options' : res < 150 ? (bottom ? '' : 'bottom short-options') : '';
  }, [res]);

  useEffect(() => {
    const new_option = !searchable ? options : filteredOptions;
    setNewOptions(new_option);
  }, [options, filteredOptions, searchable, value]);

  const handleChangeInput = useCallback(
    (e: any) => {
      setSearchText(e.target.value);
      const newOpt = [...options];
      const fil = newOpt.filter((op: {label: string}) =>
        op.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
      );
      setFilteredOptions(e.target.value && e.target.value.trim() ? fil : options);
      onChange(e.target.value);
    },
    [options, onChange],
  );

  const handleHideInput = useCallback(() => {
    searchText && setSearchText('');
    showInput && setShowInput(false);
  }, [showInput]);

  const handleShowInput = useCallback(() => {
    !showInput && setShowInput(true);
  }, [showInput]);

  return (
    <div className={`d-block ${w100 ? 'w-100' : ''}`}>
      {label && <div className="select-label primary-color">{label}</div>}
      <div
        className={`custom-universal-select noselect ${className ? className : ''} ${
          showLeft ? 'show-left' : showRight ? 'show-right' : showBottom ? 'show-bottom' : ''
        } ${fullWidth ? 'full-width' : ''} ${noBorder ? 'no-border' : ''} ${disabled ? 'disabled-select' : ''}`}
        ref={selectRef}
        onClick={() => {
          setIsShow(!isShow);
        }}
      >
        {showLeft || showRight || showBottom ? (
          <span id="icon" onClick={() => !disabled && setShowOptions(!showOptions)}>
            {icon}
          </span>
        ) : (
          <button
            className={`select ${isShow && showOptions ? 'border-color' : ''} ${danger ? 'border-danger' : ''}`}
            style={{width: width ? width : ''}}
            disabled={disabled}
            onClick={() => !disabled && setShowOptions(!showOptions)}
          >
            {searchable && (
              <div className={`${showInput ? 'show' : 'hide'}`}>
                <Input
                  value={searchText}
                  onChange={handleChangeInput}
                  className="no-border-input"
                  placeholder={typeof value === 'object' ? value?.label : value || ''}
                  onFocus={handleShowInput}
                  onBlur={handleHideInput}
                  onKeyDown={handleShowInput}
                  type="text"
                  name="country"
                />
              </div>
            )}
            {searchable && !showInput && !value && placeholder && <span className="placeholder">{placeholder}</span>}
            {placeholder && !value ? (
              <>
                {!searchable && <span className="placeholder">{placeholder}</span>}
                {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
              </>
            ) : (
              <>
                {!showInput &&
                  <span
                    className={`${textDefault ? 'text-transform-default' : ''} selected-value ${
                      value &&
                      value.label &&
                      typeof value.label === 'string' &&
                      value.label.toLowerCase().includes('select')
                        ? 'noselect'
                        : ''
                    }`}
                  >
                    {typeof value === 'object' ? value && value.label : value}
                  </span>
                }
                {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
              </>
            )}
          </button>
        )}
        {isShow && showOptions && (
          <div
            id="drop-down-profile"
            className={`options ${getClassNamesByHeight()}`}
            style={{width: width ? width : ''}}
          >
            {children
              ? children
              : newOptions && newOptions.length ?
                newOptions.map((item: any, index: number) => (
                  <p
                    className={`option ${textDefault ? 'text-transform-default' : ''} ${
                      textUppercase ? 'text-transform-upperCase' : ''
                    } ${item.value}
                  ${
                    value === item.label ||
                    (manySelect && value && value.find((v: any) => v === item.value)) ||
                    (value === 'all' && item.value === '') ||
                    ((typeof value !== 'string' || typeof value !== 'number') && value && value.value === item.value)
                      ? !manySelect
                        ? ''
                        : 'selected'
                      : ''
                  }`}
                    key={item.value}
                    onClick={() => {
                      onChange(item);
                      setSearchText('');
                      !manySelect && setShowOptions(false);
                    }}
                  >
                    {item.icon && item.icon} {item.label}
                  </p>
                )) : <div className='text-center'>No result</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Select);
