import React, {FC} from 'react';

type TextAreaProps = {
  name: string;
  placeholder?: string;
  className?: string;
  onChange: (event: React.SyntheticEvent) => void;
  value: string;
  rows: number;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  disableResize?: boolean;
};

export const TextArea: FC<TextAreaProps> = ({
  className,
  rows,
  name,
  placeholder,
  onChange,
  value,
  maxLength,
  disabled,
  disableResize,
}) => (
  <textarea
    name={name}
    placeholder={placeholder ? placeholder : ''}
    className={className ? `${className} text-area` : 'text-area'}
    // className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''}`}
    onChange={(event: React.SyntheticEvent) => onChange(event)}
    value={value}
    rows={rows}
    data-testid="textarea"
    maxLength={maxLength}
    disabled={disabled}
  />
);
