import React, {useEffect} from 'react';
import {navigate} from 'gatsby-link';
import arrowLeft from '../../images/left-arrow.svg';
import './styles.scss';

const Popup: React.FC<any> = React.memo(
  ({setShowPopup, children, showBackBtn, onBackBtnClick, noCloseIcon, className, fitContent, noNavigate}) => {
    //@ts-ignore
    useEffect(() => {
      //@ts-ignore
      document.body.style.overflow = 'hidden';
      //@ts-ignore
      return () => (document.body.style.overflow = 'unset');
    }, []);
    const handleCloseModal = () => {
      setShowPopup(false);
      !noNavigate && navigate("/");
    };
    return (
      <div className={`customModal ${className ? className : ''}`}>
        <div
          className={`modal-body position-absolute ${fitContent ? '' : 'w-100'} pt-2 pb-2 ${document.body.clientHeight < 750 ? 'top_30' : ''}`}
        >
          <div className="top">
            {showBackBtn && (
              <span className="go-back" onClick={onBackBtnClick}>
                <img src={arrowLeft} alt="back" />
              </span>
            )}
            {!noCloseIcon && (
              <span onClick={handleCloseModal} className="modal-close">
                {
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
                      fill="#8C979D"
                    />
                  </svg>
                }
              </span>
            )}
          </div>
          <div className="popup-alert">{children}</div>
        </div>
      </div>
    );
  },
);

export default Popup;
