import React from "react"
import { FormProps } from "../../types"
export { FormItem } from "./form-item"
export { Input } from "./input"
export { TextArea } from "./textarea"
import "./styles.scss"

export const Form = ({ className, onSubmit, children }: FormProps) => (
  <form
    className={className ? `${className} form` : "form"}
    onSubmit={(event: React.SyntheticEvent) => {
      event.preventDefault();
      return onSubmit()
    }}
    data-testid="form"
    action=''
    method='post'
  >
    {children}
  </form>
)
