import React from "react";
import { InputProps } from "../../../types";
import "../styles.scss";

export const Input = ({
  onKeyDown,
  className,
  type,
  name,
  placeholder,
  onChange,
  value,
  required,
  disabled,
  style,
  min,
  max,
  onBlur,
  onFocus,
  maxLength,
}: InputProps): JSX.Element => (
  <input
    onKeyDown={onKeyDown}
    required={required}
    type={type}
    name={name}
    style={style}
    disabled={disabled}
    onBlur={onBlur}
    onFocus={onFocus}
    placeholder={placeholder ? placeholder : ""}
    className={className ? `${className} input` : "input"}
    onChange={(event: React.SyntheticEvent) => onChange(event)}
    value={value}
    data-testid="input"
    min={min}
    max={max}
    autoComplete="on"
    maxLength={maxLength}
  />
);
